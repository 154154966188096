import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TrattamentoHeroContainer from "../../containers/trattamenti/trattamento-hero"
import TrattamentoMenu from "../../components/TrattamentoMenu"
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchTrattamento,
  selectTrattamentoPage,
  selectTrattamentoPageLoading,
} from "../../redux/trattamentoPageSlice"
import LoadingView from "../../components/LoadingView/LoadingView"
import withLocation from "../../utils/withLocation"
import VideoContainer from "../../components/VideoContainer/VideoContainer"

const TrattamentoVideoPage = ({ location, pageContext: { tratt } }) => {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Video",
    crumbSeparator: " / ",
  })
  const loading = useSelector(selectTrattamentoPageLoading)
  const trattamento = useSelector(selectTrattamentoPage)
  const dispatch = useDispatch()

  useEffect(() => {
    if (trattamento?.id_sys != tratt.id) {
      dispatch(fetchTrattamento(tratt.id))
    }
  }, [tratt.id])

  if (loading) {
    return <LoadingView />
  }
  return (
    <Layout>
      <SEO title={`${trattamento.trattamento} - Video`} />
      <BreadCrumbs crumbs={crumbs} />
      <TrattamentoHeroContainer tra={trattamento} />
      <TrattamentoMenu
        active={2}
        title={trattamento.trattamento}
        trattamento={tratt}
      />
      <VideoContainer id={trattamento.id_sys} />
    </Layout>
  )
}

export default withLocation(TrattamentoVideoPage)
